exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"xsMin": "480px",
	"smMin": "768px",
	"mdMin": "992px",
	"lgMin": "1200px",
	"xxsMax": "479px",
	"xsMax": "767px",
	"smMax": "991px",
	"mdMax": "1199px"
};